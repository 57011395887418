
$(document).on('click', '.shownotesbtn', function(e){

    if ($(this).hasClass('freelock')) {
        e.stopImmediatePropagation();
        handleFreelock();
        return false;
    }

    e.preventDefault();
    $('.notewidget').remove();
    $('.notescancel').click();
    let form = $(this).data('form');
    //let parent = $(this).parent('div');
    //let parent = $(this).closest('tr');
    let parent = $(this).parent();
    let attr = $(this).attr('data-targetdiv');
    if (typeof attr !== typeof undefined && attr !== false) {
        parent = ($('#' + $(this).attr('data-targetdiv')));
    }
    $.ajax({
        url: form,
        method: 'get',
        dataType: 'html',
        success: function(html){
            //$('.noteedit').html(html);
            $('#editor').append(html);
            //$('.noteedit').show();
            $("#paneleditor").animate({
                width: "toggle"
            });

        },
    });
});

$(document).on('click', '.notesave', function(e){
    e.preventDefault();
    let noclear= $(this).hasClass('noclear');
    let id = $(this).data('id');
    let element = '.noteedit_'+ id;
    let formdata = JSON.stringify($(element + ' :input').serializeArray());
    let delay = 500;
    let url = $(this).data('url');
    //alert('url: ' + url + '  -  ' + formdata + '  noclear: '+noclear);
    $.ajax({
        url: url,
        method: 'post',
        data: formdata,
        dataType: 'json',
        success: function(data){
            $('.noteview_'+id).show();
            $('.noteedit_'+id).hide();
            // alert($('#NoteText_' + id).val());
            $('.noteview_' + id).html($('#NoteText_' + id).val());
            let type= data['type'];
            let msg = data['message'];
            ShowToast('Note has been saved', 'success', delay);
            setTimeout(function () {
                if (!noclear) {
                    //alert('location reload');
                    location.reload();
                } else {
                    $('.noteclose').click();
                }
            }, delay);

        },

    });

});

$(document).on('click', '.notecancel', function(e){
    e.preventDefault();
    let id = $(this).data('id');
    $('.noteview_'+id).show();
    $('.noteedit_'+id).hide();

});

$(document).on('click', '.noteclose', function(e){
    e.preventDefault();
    $('.popeditcancel').click();
    $(this).closest('.notewidget').remove();


});

$(document).on('click', '.deletenote', function(e){
    let url = $(this).data('path');
    let noclear= $(this).hasClass('noclear');
    let nans = confirm('Deleting this note will remove it forever.  Are you sure you want to delete this note? \n\n Click "OK" to delete, or "Cancel" to return.');
    if (nans === true){
        let delay = 1000;
        $.ajax({
            url: url,
            method: 'post',
            dataType: 'json',
            success: function(data){
                let type= data['type'];
                let msg = data['message'];
                ShowToast(msg, type, delay);
                /* delay refresh till note has been seen */
                setTimeout(function () {
                    if (!noclear){
                        location.reload();
                    } else {
                        $('.noteclose').click();
                    }
                }, delay);
            },
        });
    }

});