/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

$(document).ready(function(){

    $(document).on('click', '.starttour', function(e){
        //#$('.starttour').click(function(e) {
        //e.preventDefault();
       //alert('starttour');

        let tourid  = $(this).attr('data-tourid');
        let elementid = null;
        if (typeof( $(this).attr('data-elementid') ) !== 'undefined' ){
            elementid = $(this).attr('data-elementid');
        };
        
        let url = '/starttour/' + tourid + '/' + elementid;
        $('#tour').remove();        
        // load the content from database
        var content = $.ajax({
                url: url,
                dataType: "html",
                async: false
            }).responseText;

        let elementname = $('#tour').attr('data-element');
            // test to see if the element exists on the current page
            // if not do not try to assign the element name & arrow position, etc.)
            if (!$('#' + elementname).length){
                elementname="";
            }


        $('body').append(content);
            $('#tour').addClass('tour');
            // set size and arrow 
            $('#tour').addClass($('#size').val());
            $('#tour').addClass($('#arrowlocation').val());
            $('#tour').css('z-index', '10000');
        
        //now find the element and set the position of the popup

        let screenwidth = $('body').width()*.9;
        let screenheight = $('body').height() *.8;
        let tourwidth = $('#tour').width();
        let tourheight = $('#tour').height();
        let divleft = (screenwidth-tourwidth)*.5;
        let divtop = 240;


        //alert('scrn w: ' + screenwidth + ' scrn h: ' + screenheight + ' tour w' + tourwidth + ' tour h: ' + tourheight + '  divleft: ' + divleft + ' divtop: '+divtop);

        if (elementname !== ""){
            let element = $('#tour').attr('data-element');
            let elementwidth = $('#'+element).width();
            //set default position - center screen
           //if (element.length !== 0){
                // center it on the element as default, then reset bsaed on arrowloc
                // element center = element.width/2
                // tour width/2 need to match center of element
                // element left + 1/2 of width - 1/2 tourwidth = tour left
                //var epos = $("#" + element).position();
                var epos = $("#" + element).offset();
                let arrowloc = $('#arrowlocation').val();
               
              divleft = getTourLeft(element, arrowloc);
              divtop = getTourTop(element, arrowloc);
                
           // } 
        }

            $('#tour').css({'position':'absolute'});
            $('#tour').css({'z-index':'10'});
            $('#tour').css({'top': divtop});
            $('#tour').css({'left': divleft});

        //alert('scrn w: ' + screenwidth + ' scrn h: ' + screenheight + ' tour w' + tourwidth + ' tour h: ' + tourheight + '  divleft: ' + divleft + ' divtop: '+divtop);

        $('#tour').show();

        //cleanup 
            screenwidth = null;
            screenheight = null;
            tourwidth = null;
            tourheight = null;
            epos = null;
            divleft = null;
            divtop = null;
            elementid = null;
            element = null;
            elementwidth=null;
            elementname = null;
            
        return true;
    });

    $('body').on('click', '.endtour', function(e){
       $('#tour').hide();
       $('#tour').remove();
    });
    
    function getTourLeft(element, arrow){
        arrow = arrow.toLowerCase();
        //element is the attached element, arrow is the location
        var epos = $("#" + element).offset();
        let tourleft = 0;
        let tourwidth = $('#tour').width();
        let screenwidth = $('body').width()*.9;
       
        if (arrow.indexOf('left') === 0){
            tourleft = epos.left + $('#' + element).width() + 32;
            
        } else if (arrow.indexOf('left') > 0){
            tourleft = epos.left;
            
        } else if (arrow.indexOf('right')=== 0){
           //alert(epos.left - tourwidth);
            tourleft = epos.left - tourwidth - 45;
        }else if (arrow.indexOf('right') >= 0){
            tourleft  = (epos.left + $('#'+element).width() )  - (tourwidth+10) ;
        } else {
            tourleft = (epos.left + ($('#'+element).width()*.5)) - (tourwidth *.5);
        }
        
        if (tourleft + tourwidth + 5  > screenwidth){
               tourleft = screenwidth - (tourwidth + 5) ;
        }
        
        return tourleft;
    }
    
    function getTourTop(element, arrow){
        //element is the attached element, arrow is the location
        arrow = arrow.toLowerCase();
        var epos = $("#" + element).offset();
        let tourtop = 0;
        let tourheight = $('#tour').height();
        let screenheight = $('body').height()*.9;
        if (arrow.indexOf('top')=== 0){
            tourtop = epos.top + $("#" + element).outerHeight() + 5;
        } else if(arrow.indexOf('top') > 0){
            tourtop = epos.top -20;
        } else if (arrow.indexOf('bottom') === 0){
            tourtop = epos.top - (tourheight + 62);
        } else if (arrow.indexOf('bottom') > 0){
            tourtop = (epos.top + $('#'+element).height()) - (tourheight + 40);
        } else {
            //tourtop = (epos.top + ($('#'+element).outerHeight() * .5)) - (tourheight * .5);
            tourtop = (epos.top) - (tourheight * .5);
        }
            if (tourtop < 0) {
                tourtop = 10;
            }          
        return tourtop + 12; //fix for tour hiding behind fixed header
    }
    
    
  });

