$(document).on('click', '.popeditbtn', function(e){

    if ($(this).hasClass('freelock')) {
        e.stopImmediatePropagation();
        handleFreelock();
        return false;
    }

    e.stopPropagation();
    if (!$(this).hasClass("noclear")){
        $('.popeditcancel').click();
    }

    let form = $(this).data('form');
    //let parent = $(this).parent('div');
    //let parent = $(this).closest('tr');
    let parent = $(this).parent();
    let attr = $(this).attr('data-targetdiv');
    if (typeof attr !== typeof undefined && attr !== false) {
        parent = ($('#' + $(this).attr('data-targetdiv')));
    }
    //#alert("form: " + form +  '  attr: ' + attr + '   parent: ' + $('#' + $(this).attr('data-targetdiv')));

    $.ajax({
        url: form,
        method: 'get',
        dataType: 'html',
        success: function(html){
            //alert(html);
            //$('.popedit').html(html);
            $(parent).append(html);
            $('.popedit').show();

        },
    });

    //$('#paneleditor').show();
    $("#paneleditor").animate({
        width: "toggle"
    });
});

$(document).on('click', '.popeditsave', function(e){
    e.preventDefault();
    let noclear = $(this).hasClass('noclear');

    let id = $(this).data('id');
    let element = '#popeditFORM';
    let formdata = JSON.stringify($(element).serializeArray());
    let delay = 1000;
    let url = $(this).data('url');
    //alert('url: ' + url + '  -  ' + formdata);
    $(this).closest('.popedit').remove();
    $.ajax({
        url: url,
        method: 'post',
        data: formdata,
        dataType: 'json',
        success: function(data){
            let type= data['type'];
            let msg = data['message'];

            ShowToast(msg, type, delay);
            /* delay page refresh till toast message has been seen.... */
            setTimeout(function() {
                $('#editor').html("");
               if (!noclear) {
                    location.reload();
                }
                $('.popeditcancel').click();
            }, delay);

        },
        error: function (xhr, ajaxOptions, thrownError) {
            alert('Danger Will Robinson - an error was thrown. Error Number: ' + xhr.status + ' with the message:' +  thrownError);
        }
    });

});

$(document).on('click', '.popeditcancel', function(){

    if ($("#paneleditor").is(":visible")) {
        $("#paneleditor").animate({
            width: "toggle"
        });
    }
    $('#editor').html("");

    //$(this).closest('.popedit').remove();
});

function ShowToast(msg, type, waittime) {
    let url = '/toast';
    //alert(url);
    $.ajax({
        url: url,
        method: 'get',
        dataType: 'html',
        success: function (data) {
            //alert(data);
            $(document.body).append(data);
            $('.toast-body').html(msg);
            $('.toast-body').addClass('toast-' + type);
            $('.toast').toast({
                type: type,
                delay: waittime
            });
            $('.toast').toast('show');
            $('.toast').removeClass('toast-' + type);

        }
    });

}