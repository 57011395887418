$(document).on('click', '.sendmail', function (e) {

    if ($(this).hasClass('freelock')) {
        e.stopImmediatePropagation();
        handleFreelock();
        return false;
    }

    let dealid = null;
    let contactctid = null;
    let type = null;
    let dealtaskid = null;
    let id = null;
    let targetdiv = null;
    let prospectid = null;

    e.preventDefault();
    e.stopPropagation();
    url = $(this).data('form');
    dealid = $(this).data('dealid');
    contactctid = $(this).data('contactid');
    type = $(this).data('type');
    dealtaskid = $(this).data('dealtaskid');
    id = $(this).data('id');
    targetdiv = $(this).data('targetdiv');
    prospectid = $(this).data('prospectid');

    formdata = [
        {'name': 'type', 'value': type},
        {'name': 'id', 'value': id},
        {'name': 'dealid', 'value': dealid},
        {'name': 'contactid', 'value': contactctid},
        {'name': 'dealtaskid', 'value': dealtaskid},
        {'name': 'prospectid', 'value': prospectid}
    ];
    $.ajax({
        url: url,
        method: 'post',
        data: JSON.stringify(formdata),
        dataType: 'html',
        success: function (html) {
            $('#' + targetdiv).html(html);
            $('#paneleditor').animate({
                width: "toggle"
            });
            //$('.popedit').show();
        },
    });
});


$(document).on('click', '.closemailwin', function (e) {

    $('.loader').remove();
    $('.mailwin').remove();

});


$(document).on('click', '.sendone', function (e) {
    e.preventDefault();
    e.stopPropagation();


    /* load quill to textarea in case we need to cut/paste */
    let data = $('.ql-editor').html();
    $('.clipboardtext').val(data);

    $('.senderemail').val();
    let recipients = $(this).closest('.selecteditem').data('email');
    $('.EmailRecipients').val(recipients);

    let subject = $('.subject').val();
    let body = $('.ql-editor').html();
    let cc = $('.senderemail').val();
    if ($('.CCmd').val() == 0) {
        cc = '';
    }

    let formdata = JSON.stringify($('#popeditFORM').serializeArray());

    //alert(formdata);

    let targetdiv = 'editor';
    let url = '/emailtemplates/sendemail';
    let delay = 1500;
    $.ajax({
        url: url,
        method: 'post',
        data: formdata,
        dataType: 'json',
        success: function (data) {
            let type = data['type'];
            let msg = data['message'];

            ShowToast(msg, type, delay);
            /* delay page refresh till toast message has been seen.... */
            setTimeout(function () {
                $('#editor').html("");
                $('.closemailwin').click();
                location.reload();
            }, delay);
        },
    });

    /*
        if (body.length > 250) {
            alert("message is too long to be placed in your email editor, it has been added to the clipboard, though, so simply paste in the body of your email editor.");
            copyToClip(data);
            body = '';
        }

        $("#mailer").attr("href", "mailto:" + recipients + "&cc=" + cc + "&subject=" + subject + "&body=" + body + "");
        $('#mailer')[0].click();
        setCommLog(0);
    */
});

$(document).on('click', '.sendall', function (e) {
    e.preventDefault();
    e.stopPropagation();
    /* load quill to textarea in case we need to cut/paste */
    let data = $('.ql-editor').html();
    $('.clipboardtext').val(data);

    $('.senderemail').val();
    let recipients = $('.recipients').val();
    $('.EmailRecipients').val(recipients);
    let subject = $('.subject').val();
    let body = $('.ql-editor').html();
    let cc = $('.senderemail').val();
    if ($('.CCmd').val() == 0) {
        cc = '';
    }


    let formdata = JSON.stringify($('#popeditFORM').serializeArray());
    let targetdiv = 'editor';
    let url = '/emailtemplates/sendemail';
    let delay = 1500;

    $.ajax({
        url: url,
        method: 'post',
        data: formdata,
        dataType: 'json',
        success: function (data) {
            let type = data['type'];
            let msg = data['message'];

            ShowToast(msg, type, delay);
            /* delay page refresh till toast message has been seen.... */
            setTimeout(function () {
                $('#editor').html("");
                $('.closemailwin').click();
                location.reload();
            }, delay);
        },
    });


    /*
        if (body.length > 250) {
            alert("message is too long to be placed in your email editor, it has been added to the clipboard, though, so simply paste in the body of your email editor.");
            copyToClip(data);
            body = '';
        }

        $("#mailer").attr("href", "mailto:" + recipients + "&cc=" + cc + "&subject=" + subject + "&body=" + body + "");
        $('#mailer')[0].click();
        setCommLog(1);
    */

});


$(document).on('change', '.EmailTemplateID', function () {
    $('#PREVIEW').html('');
    $('#PREVIEW').hide();
    $('.loader').show();


    if ($(this).val() > -1) {
        let url = '/emailtemplate/previewtemplate';
        let formdata = JSON.stringify($('#popeditFORM').serializeArray());
        //alert(formdata);
        $.ajax({
            url: url,
            method: 'post',
            data: formdata,
            dataType: 'html',
            success: function (data) {
                $('#readytosend').val(1);
                $('.loader').hide();
                $('#PREVIEW').html(data);
                $('#PREVIEW').show();
                $('.sendall').show();
                $('.sendone').show();
            },
        })
    } else {
        $('.loader').hide();
        $('#PREVIEW').html('');
        $('#PREVIEW').hide();
        $('.sendall').hide();
        $('#readytosend').val(0);
        $('.sendone').hide();

    }
});

function setCommLog(reload) {

    let url = '/commlog/logdealemail';

    let formdata = JSON.stringify($('#popeditFORM').serializeArray());
    let delay=1000;
    //alert(formdata);
    $.ajax({
        url: url,
        method: 'post',
        data: formdata,
        dataType: 'html',
        success: function (data) {
            let type = data['type'];
            let msg = "Email saved to the communications log";
            ShowToast(msg, type, delay);
            /* delay page refresh till toast message has been seen.... */
            setTimeout(function () {
                if (parseInt(reload) > 0) {
                    location.reload();
                }
            }, delay);
        },
    })
}

function copyToClip(str) {
    function listener(e) {
        e.clipboardData.setData("text/html", str);
        e.clipboardData.setData("text/plain", str);
        e.preventDefault();
    }

    document.addEventListener("copy", listener);
    document.execCommand("copy");
    document.removeEventListener("copy", listener);
};