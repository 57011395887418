/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

$(document).on('click', '.archivedeal', function () {

    let nans = confirm("By archiving this deal you won't be able to edit it any more.  Are you sure you want to do this? ");
    if (nans === true) {
        let delay = 1000;
        let id = $(this).data('dealid');
        let url = "/archivedeal/" + id;
        $.ajax({
            url: url,
            method: 'get',
            dataType: 'json',
            success: function (data) {

                let type = data['type'];
                let msg = data['message'];
                ShowToast(msg, type, delay);
                /* delay page refresh till toast message has been seen.... */
                setTimeout(function () {
                    $('#editor').html("");

                    location.reload();
                }, delay);

            },
            error: function (xhr, ajaxOptions, thrownError) {
                alert('Danger Will Robinson - an error was thrown. Error Number: ' + xhr.status + ' with the message:' + thrownError);
            }
        });
    }
});

$(document).on('click', '.unarchivedeal', function (e) {

    if ($(this).hasClass('deallock')) {
        e.stopPropagation();
        handleDeallock();
        return;
    }

    let nans = confirm("By un-archiving this deal makes it active and counts to your active deals. Are you sure you want to do this? ");
    if (nans === true) {
        let delay = 1000;
        let id = $(this).data('dealid');
        let url = "/unarchivedeal/" + id;
        $.ajax({
            url: url,
            method: 'get',
            dataType: 'json',
            success: function (data) {

                let type = data['type'];
                let msg = data['message'];
                ShowToast(msg, type, delay);
                /* delay page refresh till toast message has been seen.... */
                setTimeout(function () {
                    $('#editor').html("");

                    location.reload();
                }, delay);

            },
            error: function (xhr, ajaxOptions, thrownError) {
                alert('Danger Will Robinson - an error was thrown. Error Number: ' + xhr.status + ' with the message:' + thrownError);
            }
        });
    }
});

