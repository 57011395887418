$(document).ready(function(){

    $(document).on("click",".loadmodal", function(e) {

        $(".modal-dialog").removeClass("modal-lg modal-sm modal-xl");

        $('#cci-modal').find('.modal-body').html('');  // clean out modal.

        var size = $(e.target).data('modalsize');
        switch (size) {
            case 'lg':
                $('.modal-dialog').addClass('modal-lg');
                break;
            case 'sm':
                $('.modal-dialog').addClass('modal-sm');
                break
            case 'xl':
                $('.modal-dialog').addClass('modal-xl');
                break;
            case 'md':
                // medium sized is the default - nothing to do
                break;
            default:
            // do nothing
        }
        //alert($(e.target).data('path' )+ '/modal');

        $.ajax({
            type: 'GET',
            url: $(e.target).data('path' )+ '/modal',
            dataType: 'html',
            success: function(data){
                //$('#cci-modal').find('.modal-title').html($(e.target).data('title');;
                $('#cci-modal').find('.modal-body').html(data);
                $('#cci-modal').modal("show");
            }
        });

        return true;
    });

    $(document).on('click', '.loadpage', function(e){
        // works when clicking an "i" element that is classed as load page
        // element must be wrapped in an anchor <a href="#")
        var newurl = $(e.target).data('path') + '/page' ;
        window.open(newurl, '_self');

    });

    $('#cci-modal').on('hidden.bs.modal', function () {
        if ($('#stdtable').length){
            reloadtable(0);
        } else {
            location = location;
        }
    })

    $(document).on("click",".apicall", function(e) {
        let url = $(e.target).data('path');
        //alert('apicall: ' + url);
        $.ajax({
            type: 'POST',
            url: url,
            dataType: 'html',
            success: function(data){
               // alert('success!');
            },
            error: function( jqXhr, textStatus, errorThrown){
                alert('There was a problem ...');
                console.log( errorThrown );
            }
        });

        return true;
    });

});
