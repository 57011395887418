/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
//require('bootstrap/dist/css/bootstrap.css');
//require('font-awesome/css/font-awesome.css');
//require('../css/cci.css');
require('../css/global.scss');
//require('../css/app.css');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
//const $ = require('jquery');
//window.$ = window.jQuery = $;

//const dt = require( 'datatables.net-bs4' );

// Allow jQuery to be used outside of Webpack
global.$ = global.jQuery = $;

require('bootstrap');
// require("datatables.net-bs4");
require('jszip/dist/jszip.min.js');
require('jszip/vendor/FileSaver.js');
/*
require('datatables.net/js/jquery.dataTables.js');
require('datatables.net-buttons/js/dataTables.buttons.js');
require('datatables.net-buttons/js/buttons.html5.js');
require('datatables.net-buttons/js/buttons.colVis.js');
require('datatables.net-buttons/js/buttons.flash.js');
require('datatables.net-buttons-bs4');
*/
require("../js/modal.js");
require("../js/table.js");
require("../js/popedit.js");
require("../js/sortlist.js");
require("../js/notes.js");
require("../js/sidebarnav");
require("../js/emailtemplates.js");
require("../js/webtour/jtwebtour.js");
require("../js/dealarchive.js");

global.Calendar = require("tui-calendar");

//require('datatables.net-responsive/js/dataTables.responsive.min.js');
//require('popper.js');
//newcomment

//quill
global.Quill = require("quill");

global.JSZip = require("jszip");

//console.log('Hello Webpack Encore! Edit me in assets/js/app.js');