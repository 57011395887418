/*
$(document).on('click', '.togglesidenav', function(){

    if ($('.sidebar').width() > 55 ){
        $('.sidebar').width(55);
        $('.maincontent').css('margin-left', '80px');
        $('.maincontent').css('width', '90%');
        $('.togglesidenav').html('<i class="fa fa-chevron-right fa-inverse "></i>');
        $('.togglesidenav').css('margin-left', '23px');
        $('.sidebar').css('opacity', '0.75');
        $('.sidebar .widget').each(function(){
            $(this).hide();
        })
    } else {
        $('.sidebar').width(250);

        $('.maincontent').css('margin-left', '282px');
        $('.maincontent').css('width', '80%');
        $('.togglesidenav').html('<i class="fa fa-chevron-left fa-inverse"></i>');
        $('.togglesidenav').css('margin-left', '220px');
        $('.sidebar').css('opacity', '0.75');
        $('.sidebar .widget').each(function(){
            $(this).show();
        })
    }
}) ;
*/
$(document).on('click', '.sidebaritem', function(){
    let url = $(this).data('path');
    window.open(url, '_self');
});