/* 
 * To change this license header, choose License Headers in Project Properties.
 * To change this template file, choose Tools | Templates
 * and open the template in the editor.
 */

$(document).on('click', '.sortlistbtn', function(){
    $('.sortlistcancel').click();
    let form = $(this).data('form');
    //let parent = $(this).parent('div');
    //let parent = $(this).closest('tr');
    let parent = $(this).parent();
    let attr = $(this).attr('data-targetdiv');
    if (typeof attr !== typeof undefined && attr !== false) {
        parent = ($('#' + $(this).attr('data-targetdiv')));
    }
    $.ajax({
        url: form,
        method: 'get',
        dataType: 'html',
        success: function(html){
            //$('.popedit').html(html);
            $(parent).append(html);
            $('.popedit').show();

        },
    });
    //$('#paneleditor').show();
    $("#paneleditor").animate({
        width: "toggle"
    });
});

$(document).on('click', '.sortlistsave', function(){
    formdata = setOrderList();
    let id = $(this).data('id');
    let delay = 1000;
    let url = $(this).data('url');
    //alert('url: ' + url + '  formdata: '+ formdata);

    $.ajax({
        url: url,
        method: 'post',
        data: formdata,
        dataType: 'json',
        success: function(data){
            let type= data['type'];
            let msg = data['message'];
            ShowToast(msg, type, delay);
        },
    });
    /* delay page refresh till toast message has been seen.... */
    setTimeout(function() {
        let parent = $(this).closest('tr');
        $(this).closest('.popedit').remove();
        location.reload();
    }, delay);
});

$(document).on('click', '.sortlistcancel', function(){
    if ($("#paneleditor").is(":visible")) {
        $("#paneleditor").animate({
            width: "toggle"
        });
    }
    $('#editor').html("");
    //$(this).closest('.popedit').remove();
});

function setOrderList(){
    let list = $('.sortlist li');
    let cntr = 0;
    let neworder=[];

    $(list).each(function(idx, li){
        cntr++;
        neworder.push({"itemid":$(li).val(), "order": cntr});
    });
    return JSON.stringify(neworder);
}
